import { loadBaseConfig } from 'aaden-base-model/lib/Models/GlobalSettings'
import { LocalSettingManager } from 'biewangle'

const defaultConfig = require('@/assets/AadenConfig.json')
let GlobalConfig = Object.assign({}, defaultConfig)

export let NeededKeys = []

export async function loadConfig () {
  try {
    const fix = require('@/assets/FixedConfig.json')
    GlobalConfig = Object.assign(GlobalConfig, await loadBaseConfig(defaultConfig), fix)
    NeededKeys = GlobalConfig.neededKeys
    window.Config = GlobalConfig
  } catch (e) {
    console.log(e)
  }
}

export const Remember = LocalSettingManager.config({
  timeRange: 7
})
export default GlobalConfig
