import hillo from 'hillo'
import {drop, findIndex, reverse} from 'lodash-es'
import IKUtils from 'innerken-js-utils'
import dayjs from 'dayjs'
import {host} from '@/main'
import i18n from "@/i18n";


const name = 'dishResource/'

export async function getResourceList() {
    return (await hillo.jsonPost(host + name + 'getList', {})).data
}

export async function getDetailList(dateTimeFrom, dateTimeTo) {
    return (await hillo.jsonPost(host + 'resourceLog/getDetailList', {
        dateTimeFrom, dateTimeTo
    })).data
}

export async function storageChange(resourceId, resourceUnitId, count, price, note) {
    const word = count > 0 ? 'add' : 'subtract'
    return (await hillo.jsonPost(host + name + word + 'CurrentCountByUnit/' + resourceId, {
        countOnUnit: Math.abs(count), resourceUnitId, note, newPrice: price
    }))
}

export async function createCheck() {
    return (await hillo.jsonPost(host + 'inventoryRecord/createCheck', {}))
}

export async function setNewNumber(dishResourceId, realNum) {
    return (await hillo.jsonPost(host + 'inventoryRecord/check', {
        dishResourceId, realNum
    }))

}

export async function getTagList() {
    return (await hillo.jsonPost(host + 'resourceTag/getList', {})).data
}

export async function deleteTag(id) {
    return (await hillo.jsonPost(host + 'resourceTag/delete/' + id, {}))
}

export async function createTag(name, color) {
    return (await hillo.jsonPost(host + 'resourceTag/create', {
        name, color
    }))
}

export async function editTagForResource(id, tagIds) {
    return (await hillo.jsonPost(host + 'dishResource/editTags/' + id, {
        tagIds
    }))
}


export function amountUnitsDisplay(amount, units) {
    const tmpUnit = IKUtils.deepCopy(units)
    if (amount === 0) {
        return 0 + tmpUnit.pop().name
    } else {
        let finalFactor = 1


        reverse(tmpUnit).forEach(it => {
            finalFactor *= it.nextLevelFactor
            it.finalFactor = finalFactor

        })

        let rest = amount
        const result = []
        const sign = amount > 0 ? 1 : -1
        reverse(tmpUnit).forEach(it => {

            const [count, r] = divideWithRest(rest, it.finalFactor)
            if (count !== 0) {
                result.push(Math.abs(count) + it.name)
            }
            rest = r
        })
        return (sign === 1 ? '' : '-') + result.join(' ')
    }
}


export function getLowestUnitCount(count, unitId, units) {


    const level = findIndex(units, (d) => d.id === unitId)
    let result = count

    drop(units, level).forEach(it => {
        result = result * it.nextLevelFactor
    })

    return result


}

function divideWithRest(first, second) {
    return [parseInt(first / second), first % second]
}

export const timeRangeArr = [1, 2, 3, 4, 5, 6, 7, 14, 30]


export function getTimeSlotByTimeRange(d) {
    const end = dayjs().endOf('d').add(3, 'hour').add(59, 'minute').toISOString()
    const start = dayjs().subtract(d, 'd').startOf('d').add(3, 'hour').add(59, 'minute').toISOString()
    return [start, end]
}

export function getTimeByTimeRangeToShow(d) {
    const dayTimeFormat = 'YYYY-MM-DD'
    const end = dayjs().endOf('d').add(3, 'hour').add(59, 'minute').format(dayTimeFormat)
    const start = dayjs().subtract(d, 'd').startOf('d').add(3, 'hour').add(59, 'minute').format(dayTimeFormat)
    return [start, end]
}

export const baseUrl = 'http://resource9002.ngrok.aaden.io/'

export async function getInOutLog(dateTimeFrom = null, dateTimeTo = null) {
    return (await hillo.jsonPost(host + 'resourceLog/' + 'getDetailList', {
        dateTimeFrom,
        dateTimeTo,
    })).data
}

export async function sureToReturn (action, content = '', title = i18n.t('你确定要取消吗') + '?') {
    const res = await IKUtils.showConfirmAsyn(i18n.t(content), i18n.t(title))
    IKUtils.showLoading(true)
    if (res.isConfirmed) {
        await action()
        IKUtils.toast(i18n.t('取消成功'), 'success')
    } else {
        IKUtils.toast(i18n.t('取消失败'), 'info')
    }
}
