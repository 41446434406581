<template>
  <div
      v-if="detailedLog.length>0"
      class="pa-1 mt-4"
  >
    <v-card
        v-for="{changeLog,dishResource} in detailedLog"
        :key="changeLog.id"
        class="pa-4 mb-1 d-flex align-center"
        elevation="0"
    >
      <div style="width: 96px">
        <div class="text-caption">
          {{ changeLog.createdAt | beautifulTime }}
        </div>
        <v-chip
            :color="changeLog.operation==='Enter'?'success darken-4':'error darken-4'"
            label
            small
        >
          {{ changeLog.operation }}
        </v-chip>
      </div>
      <div>
        <div class="text-body-1 font-weight-bold">
          {{ dishResource.dishResource.name }}
        </div>
        <div class="text-body-2">
          {{ changeLog.note || '没有备注' }}
        </div>
      </div>
      <v-spacer/>

      <div class="mr-4">
        <div class="d-flex">
          <v-icon
              v-if="changeLog.amount<0"
              color="warning"
          >
            mdi-trending-down
          </v-icon>
          <v-icon
              v-if="changeLog.amount>=0"
              color="success"
          >
            mdi-trending-up
          </v-icon>
          <div style="width: 96px;text-align: right">
            {{ changeLog.unitDisplay }}
          </div>
        </div>
        <div class="d-flex text-body-2">
          <v-icon>
            mdi-cash-multiple
          </v-icon>
          <div style="width: 96px;text-align: right">
            {{ changeLog.costLiteral | priceDisplay }}
          </div>
        </div>
      </div>
      <v-icon x-large @click="cancelOperation(changeLog,dishResource)">
        mdi-backup-restore
      </v-icon>
    </v-card>
    <v-dialog v-model="cancelDialog" max-width="400px">
      <v-card class="pa-6">
        <div class="d-flex align-center mb-4">
          <v-spacer/>
          <div>
            <v-btn
                icon
                @click="cancelDialog = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="d-flex justify-center font-weight-bold text-h5">您确认取消该次操作吗？</div>
        <div class="d-flex justify-center mt-8">
          <v-btn class="primary" @click="sureToCancel">
            确认
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
  <div v-else>
    <div
        class="d-flex align-center justify-center flex-column"
        style="height: 400px;width: 100%"
    >
      <v-icon size="64">
        mdi-tea
      </v-icon>
      <div>
        {{ $t('thisCheckHaveNotChange') }}
      </div>
    </div>
  </div>
</template>

<script>

import {storageChange, sureToReturn} from "@/js/api/api";

export default {
  name: "RecordPage",
  props: {
    detailedLog: {},
  },
  data: () => ({
    cancelDialog: false,
  }),
  methods:{
    async cancelOperation(changeLog,dishResource){
      const res = dishResource.unitSetWithUnit.resourceUnits.find(it => it.countOnUnit !== 0)
      await sureToReturn(async ()=>{
        if(changeLog.operation === 'Enter'){
          await storageChange(
              dishResource.dishResource.id,
              res.id,
              -res.countOnUnit,
              changeLog.priceLiteral,
              '取消入库')
        } else {
          await sureToReturn(async ()=>{
            await storageChange(
                dishResource.dishResource.id,
                res.id,
                res.countOnUnit,
                changeLog.priceLiteral,
                '取消出库')
          })
        }
      })
      this.$emit('reloadPage')
    },
  },
}
</script>

<style scoped>

</style>
