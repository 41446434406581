<template>
  <div>

    <div style="display: grid;grid-template-columns: 1fr;grid-gap: 4px">
      <template>
        <v-card class="pa-4"
                color="grey lighten-4"
                elevation="0"
                :key="r.dishResource.id"
                v-for="r in resource"
                @click="startOrder(r)"
        >
          <div class="d-flex align-center">
            <div>
              <div class="text-h5 font-weight-bold">{{ r.dishResource.name }}</div>
              <div class="mr-4 d-flex">
                <v-card tile elevation="0"
                        width="140"
                        :class="r.dishResource.currentCount<(outRecordDict[r.dishResource.id]||0)?
                    'error':'success'"
                        dark>
                  <div class="text-body-1">
                    <div class="pa-1 darken-2 d-flex align-center">
                      <div class="font-weight-bold">
                        {{ resourceDisplay(r) }}
                      </div>
                      <v-spacer/>
                      <div class="text-caption">库存</div>
                    </div>
                  </div>
                </v-card>
                <v-card
                    width="160"
                    elevation="0" tile>
                  <div class="text-body-1">
                    <div class="pa-1
                  darken-2
                  align-center
                  d-flex
                 ">
                      <v-icon size="16" color="warning darken-2" left>mdi-trending-down</v-icon>
                      <div class="text-caption">{{ currentTimeRange| dayDisplay }}</div>
                      <v-spacer/>
                      {{ resourceDisplay(r, outRecordDict[r.dishResource.id] || 0) }}
                    </div>
                  </div>
                </v-card>
              </div>
            </div>

            <v-spacer></v-spacer>
            <div v-if="(orderDict[r.dishResource.id]||0)===0">
              <v-icon>mdi-plus-box</v-icon>
            </div>
            <div v-else>


              <div class="text-caption">
                <div
                    class="py-1 d-flex "
                >
                  订购后总数
                  <v-spacer></v-spacer>
                  <div class="font-weight-bold text-decoration-underline">
                    {{ resourceDisplay(r, (orderDict[r.dishResource.id] || 0) + r.dishResource.currentCount) }}
                  </div>
                </div>
              </div>

              <v-card
                  width="160"
                  elevation="0" tile>
                <div class="text-h5">
                  <div class="pa-1
                  primary
                  text-right
                  white--text
                  darken-2
                 ">
                    + {{ resourceDisplay(r, orderDict[r.dishResource.id] || 0) }}
                  </div>
                </div>
              </v-card>
            </div>

          </div>
        </v-card>
      </template>
      <v-card
          dark
          tile
          height="64"
          style="position: fixed;bottom: 0;left: 0;right: 0;"
          class="d-flex align-center px-4"
      >
        <div class="mr-4">
          <div class="text-caption">
            已经订购{{ orderList.length }}种商品
          </div>
          <div>
            预估价格 {{ orderTotal | priceDisplay }}
          </div>
        </div>
        <v-spacer/>
        <v-btn
            @click="orderByTime"
            color="indigo lighten-4"
            light>
          按照周期订购原料
        </v-btn>
        <v-btn
            color="green lighten-4"
            @click="fillItUp"
            light class="ml-4">补齐所有原料
        </v-btn>
        <v-btn
            class="ml-4"
            @click="orderDict={}"
            v-if="orderList.length>0" color="pink lighten-4" light>
          <v-icon left>mdi-close</v-icon>
          清空
        </v-btn>
        <v-btn class="ml-4" @click="showOrderListDialog=true" large color="primary">
          <v-icon left>mdi-clipboard-list</v-icon>
          查看订购列表
        </v-btn>
      </v-card>

    </div>
    <v-dialog max-width="700px" v-model="showCheckDialog">
      <v-card v-if="selectedResource">
        <div style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr))">
          <v-sheet class="pa-4" style="position:relative;">
            <div class="d-flex align-center">
              <div>
                <div class="text-caption">名称</div>
                <div class="text-h4 font-weight-black">
                  {{ selectedResource.dishResource.name }}
                </div>
              </div>
              <v-spacer></v-spacer>
              <div>
                <div class="text-caption">当前的记录是</div>
                <div class="text-h4">{{ resourceDisplay(selectedResource) }}</div>
              </div>
            </div>
            <v-divider class="my-4"></v-divider>
            <div>
              <div v-for="log in countLog" :key="log.id" class="py-2 d-flex">
                <v-icon>{{ log.sign > 0 ? 'mdi-plus' : 'mdi-minus' }}</v-icon>
                <v-spacer></v-spacer>
                {{ log.amount }}
                {{ log.unitName }}
              </div>

            </div>
            <div v-if="countLog.length>0" style="width: 100%;
                 position: absolute;bottom: 0;left: 0">
              <div class="text-h4 pa-4 d-flex"
              >
                总计
                <v-spacer></v-spacer>
                {{ amountUnitsDisplay(currentTotalAmount, selectedResource.unitSetWithUnit.resourceUnits) }}
              </div>
            </div>
          </v-sheet>
          <v-sheet dark>
            <div class="pa-2 py-4">
              <v-sheet color="grey darken-2"
                       class="pa-2 text-h5 font-weight-black d-flex align-center">
                <div>
                  {{ amount ? amount : '请输入需要订购的数量' }}
                </div>
                <v-spacer></v-spacer>

                <v-card @click="toggleUnit(unit.id)"
                        :key="unit.name"
                        v-for="unit in activeUnitList"
                        width="48px"
                        height="48px"
                        elevation="0"
                        :color="((activeUnitId===unit.id)?
                    'grey darken-2':'grey darken-4')"
                        class="text-h5 ml-1 d-flex align-center justify-center flex-shrink-0">
                  {{ unit.name }}
                </v-card>
              </v-sheet>

            </div>
            <keyboard @input="input" :keys="keyList" :colors="colorList"/>
          </v-sheet>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog max-width="500px" v-model="showOrderListDialog">
      <v-card>
        <div style="display: grid;grid-template-columns: 1fr 160px">
          <div class="pa-2" id="list" style="max-height: 480px;overflow-y: scroll">
            <div class="pa-2 d-flex align-center text-h6 font-weight-bold">订购的货品列表</div>
            <div class="mt-4">
              <v-simple-table style="background: transparent">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th style="text-align: left">
                      名称
                    </th>
                    <th style="text-align: right">
                      数量
                    </th>
                    <th style="text-align: right">
                      预估价格
                    </th>
                  </tr>
                  </thead>
                  <tbody class="text-body-1">
                  <tr
                      :key="o.dishResource.id" v-for="o in orderList"
                  >
                    <td>{{ o.dishResource.name }}</td>
                    <td style="text-align: right">× {{ resourceDisplay(o, o.amount) }}</td>
                    <td style="text-align: right">{{ o.amount * o.dishResource.lowestUnitPrice | priceDisplay }}</td>
                  </tr>
                  <tr
                  >
                    <td colspan="3">
                      总计： {{ orderTotal | priceDisplay }}
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
          <v-card height="100%" min-height="480px" dark>
            <div style="display: grid;grid-template-rows: repeat(3,minmax(0,1fr))">
              <v-card tile color="primary darken-2" width="100%" class="d-flex align-center justify-center">
                <v-responsive :aspect-ratio="1">
                  <div style="width: 100%;height: 100%" class="d-flex flex-column align-center justify-center">
                    <v-icon size="36">mdi-email-fast</v-icon>
                    <div class="mt-2">发送到邮箱</div>
                  </div>
                </v-responsive>
              </v-card>
              <v-card disabled tile color="success darken-2" width="100%" class="d-flex align-center justify-center">
                <v-responsive :aspect-ratio="1">
                  <div style="width: 100%;height: 100%" class="d-flex flex-column align-center justify-center">
                    <v-icon size="36">mdi-robot-happy</v-icon>
                    <div class="mt-2">发送到老板端App</div>
                    <div class="text-caption">敬请期待</div>
                  </div>
                </v-responsive>
              </v-card>
              <v-card v-print="'#list'" color="warning darken-2" width="100%"
                      class="d-flex align-center justify-center">
                <v-responsive :aspect-ratio="1">
                  <div style="width: 100%;height: 100%" class="d-flex flex-column align-center justify-center">
                    <v-icon size="36">mdi-cloud-print</v-icon>
                    <div class="mt-2">打印</div>
                  </div>
                </v-responsive>
              </v-card>
            </div>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog max-width="700px" v-model="showOrderByTimeDialog">
      <v-card>
        <div style="display: grid;grid-template-columns: 1fr 260px">
          <div class="pa-2" id="list" style="max-height: 480px;overflow-y: scroll">
            <div class="pa-2 d-flex align-center text-h6 font-weight-bold">订购的货品列表</div>
            <div class="mt-4">
              <v-simple-table style="background: transparent">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th style="text-align: left">
                      名称
                    </th>
                    <th style="text-align: right">
                      数量
                    </th>
                    <th style="text-align: right">
                      预估价格
                    </th>
                  </tr>
                  </thead>
                  <tbody class="text-body-1">
                  <tr
                      :key="o.dishResource.id" v-for="o in willOrderList"
                  >
                    <td>{{ o.dishResource.name }}</td>
                    <td style="text-align: right">× {{ resourceDisplay(o, o.amount) }}</td>
                    <td style="text-align: right">{{ o.amount * o.dishResource.lowestUnitPrice | priceDisplay }}</td>
                  </tr>
                  <tr
                  >
                    <td colspan="3">
                      总计： {{ willOrderTotal | priceDisplay }}
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
          <v-card height="100%" min-height="480px" dark>
            <div style="height: 100%" class="d-flex flex-column">
              <div class="pa-2">
                <div class="text-subtitle-1 mb-2 mt-2 ">
                  当前计算的销售周期
                </div>
                <div class="text-body-1 mb-4 font-weight-bold">
                  {{ currentTimeRange |dayDisplay }}
                </div>
                <div class="text-subtitle-1 mb-2 mt-2 d-flex">
                  选择要订购的时间倍数
                  <v-spacer></v-spacer>
                  {{ timeMultiple }}×
                </div>

                <v-slider hide-details v-model="timeMultiple" step="0.1" min="1" max="6"/>
                <div class="text-subtitle-1 mb-2 mt-4 ">
                  最终订货的周期基准
                </div>
                <div class="text-h5 font-weight-black mb-4">
                  {{ currentTimeRange * timeMultiple |dayDisplay }}
                </div>
              </div>
              <v-spacer></v-spacer>
              <v-btn @click="takeOrder" color="primary" height="72" x-large class="text-h6">
                <v-icon left>mdi-check</v-icon>
                确定
              </v-btn>
            </div>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import { amountUnitsDisplay, getDetailList, getLowestUnitCount, getTimeSlotByTimeRange } from '@/js/api/api'
import { groupBy, keyBy, mapValues } from 'lodash-es'
import Keyboard from '@/components/Keyboard.vue'
import print from 'vue-print-nb'

const keyList = [
  "7", "8", "9", "C",
  "4", "5", "6", "加",
  "1", "2", "3", "减",
  "清空", "0", ".", "好的"
]

const colorList = [
  "", "", "", "",
  "", "", "", "success darken-2 ",
  "", "", "", "warning darken-2",
  "error darken-2", "", "", "primary darken-2 "
]
let counter = 0
export default {
  name: "OrderPage",
  components: {Keyboard},
  props: {
    resource: {},
    currentTimeRange: {}
  },
  directives: {
    print
  },
  computed: {
    showCart () {
      return this.resource.some(it => it.count > 0)
    },
    timeRange () {
      return getTimeSlotByTimeRange(this.currentTimeRange)
    },
    activeUnitList () {
      return this.selectedResource.unitSetWithUnit.resourceUnits.filter(it => !this.hideUnitPick || it.id === this.activeUnitId)
    },
    currentTotalAmount () {
      return this.selectedResource.dishResource.currentCount + this.countLog.reduce((sum, i) => {
        return sum + i.realAmount * i.sign
      }, 0)
    },
    resourceDict () {
      return keyBy(this.resource, 'dishResource.id')
    },
    orderList () {
      return Object.keys(this.orderDict).map(it => {
        return {
          ...this.resourceById(it),
          amount: this.orderDict[it]
        }
      })
    },
    willOrderList () {
      return this.resource.map(it => {
        it.amount = (this.outRecordDict[it.dishResource.id] ?? 0) * this.timeMultiple
        return it
      })
    },
    willOrderTotal () {
      return this.willOrderList.reduce((sum, i) => sum + (i.amount * i.dishResource.lowestUnitPrice), 0)
    },
    orderTotal () {
      return this.orderList.reduce((sum, i) => sum + (i.amount * i.dishResource.lowestUnitPrice), 0)
    }
  },
  watch: {
    timeRange: {
      immediate: true,
      async handler (val) {
        await this.refreshOutRecord(val)
        this.currentOrderTimeRange = this.currentTimeRange
      }
    }
  },
  data () {
    return {
      outRecordDict: [],
      orderDict: {},
      showCheckDialog: false,
      keyList,
      colorList,
      amount: '',
      selectedResource: null,
      hideUnitPick: true,
      countLog: [],
      activeUnitId: null,
      showOrderListDialog: false,
      showOrderByTimeDialog: false,
      timeMultiple: 1
    }
  },
  methods: {
    orderByTime () {
      this.timeMultiple = 1
      this.showOrderByTimeDialog = true
    },
    fillItUp () {
      const notFull = this.resource.filter(r => r.dishResource.currentCount < (this.outRecordDict[r.dishResource.id] ?? 0))
      notFull.forEach(it => {
        const shortAmount = (this.outRecordDict[it.dishResource.id] ?? 0) - it.dishResource.currentCount
        this.setOrderAmount(it.dishResource.id, shortAmount)
      })
    },
    takeOrder () {
      this.showOrderByTimeDialog = false
      this.willOrderList.forEach(it => {
        this.setOrderAmount(it.dishResource.id, it.amount)
      })
    },
    resourceById (id) {
      return this.resourceDict[id]
    },
    amountUnitsDisplay,
    input (key) {
      let sign = 1
      switch (key) {
        case '加':
        case '减':
          if (!this.amount) {
            return
          }
          sign = key === '加' ? 1 : -1
          this.countLog.push({
            sign,
            amount: this.amount,
            unitId: this.activeUnitId,
            unitName: this.selectedResource.unitSetWithUnit.resourceUnits.find(it => it.id === this.activeUnitId).name,
            id: counter++,
            realAmount: getLowestUnitCount(this.amount, this.activeUnitId, this.selectedResource.unitSetWithUnit.resourceUnits)
          })
          this.amount = ''

          break
        case '好的':
          if (this.amount) {
            this.countLog.push({
              sign,
              amount: this.amount,
              unitId: this.activeUnitId,
              unitName: this.selectedResource.unitSetWithUnit.resourceUnits.find(it => it.id === this.activeUnitId).name,
              id: counter++,
              realAmount: getLowestUnitCount(this.amount, this.activeUnitId, this.selectedResource.unitSetWithUnit.resourceUnits)
            })
          }
          this.amount = ''
          this.showCheckDialog = false
          this.setOrderAmount(
              this.selectedResource.dishResource.id,
              this.currentTotalAmount
              - this.selectedResource.dishResource.currentCount
          )
          break
        case '清空':
          this.countLog = []
          this.amount = ''
          break
        case 'C':
          this.amount = ''
          break
        default:
          this.amount += key
      }
    },
    toggleUnit (id) {
      if (this.hideUnitPick) {
        this.hideUnitPick = false
      } else {
        this.activeUnitId = id
        this.hideUnitPick = true
      }
    },
    async refreshOutRecord (timeRange) {
      this.outRecordDict = mapValues(groupBy((await getDetailList(...timeRange)).filter(it => {
        return it.changeLog.operation === 'Out'
      }), (it) => it.dishResource.dishResource.id), (it) => {
        return Math.abs(it.reduce((sum, i) => sum + i.changeLog.amount, 0))
      })
    },
    resourceDisplay (r, overrideAmount = null) {
      return amountUnitsDisplay(overrideAmount ?? r.dishResource.currentCount, r.unitSetWithUnit.resourceUnits)
    },
    startOrder (resource) {
      this.countLog = []
      this.selectedResource = resource
      if (this.orderDict[resource.dishResource.id]) {
        const amount = this.orderDict[resource.dishResource.id]
        const unit = resource.unitSetWithUnit.resourceUnits[0]
        this.countLog.push({
          sign: 1,
          amount,
          unitId: unit.id,
          unitName: unit.name,
          id: counter++,
          realAmount: amount
        })
      }
      this.activeUnitId = this.selectedResource.unitSetWithUnit.resourceUnits[0].id
      this.showCheckDialog = true
    },
    setOrderAmount (resourceId, amount) {
      this.$set(this.orderDict,
          resourceId,
          amount
      )
    }
  }
}
</script>

<style scoped>
div::-webkit-scrollbar {
  display: none;
}

</style>
