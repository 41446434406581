<template>
  <home-page/>
</template>

<script>
import HomePage from './components/HomePage'

export default {
  name: 'App',

  components: {
    HomePage
  },

  data: () => ({
    //
  })
}
</script>
<style>
body {
  user-select: none;

}

div::-webkit-scrollbar {
  display: none;
}
</style>
