<template>
  <div style="display: grid;grid-template-columns: 1fr;grid-gap: 4px">
    <template v-if="!editMode">
      <v-card v-for="r in resource"
              :key="r.dishResource.id"
              class="pa-4"
              color="grey lighten-4" elevation="0"
              @click="editResource(r)"
      >
        <div class="d-flex align-center">
          <resource-name-display :resource="r"/>
          <v-spacer></v-spacer>
          <div class="mr-2" style="width: 160px">
            <div class="text-caption">{{ currentTimeRange | dayDisplay }}销售</div>
            <div class="text-h5">
              <div class="pa-2 rounded
                  indigo darken-2
                   white--text">{{ resourceDisplay(r, outRecordDict[r.dishResource.id] || 0) }}
              </div>
            </div>
          </div>
          <div style="width: 160px">
            <div class="text-caption">现在还有</div>
            <div class="text-h5">
              <div :class="r.dishResource.currentCount<(outRecordDict[r.dishResource.id]||0)?'error':'success'"
                   class="pa-2 rounded darken-2 white--text">
                {{ resourceDisplay(r) }}
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </template>
    <template v-else>
      <v-card v-for="r in resource"
              :key="r.dishResource.id"
              class="pa-4"
              color="grey lighten-4" elevation="0"
              @click="editResource(r)"
      >
        <div class="d-flex align-center">
          <resource-name-display :resource="r"/>
          <v-spacer></v-spacer>
        </div>
      </v-card>
    </template>
    <v-dialog v-model="showEditDialog" max-width="400px">
      <template v-if="!editMode">
        <v-card v-if="selectedResource">
          <v-card class="pa-2" dark elevation="0">
            <div class="d-flex align-center">
              <div>
                <div class="text-caption">名称</div>
                <div class="text-body-1 font-weight-black">
                  {{ selectedResource.dishResource.name }}
                </div>
              </div>
              <v-spacer></v-spacer>
              <div class="mr-6">
                <div class="text-caption">入库价格</div>
                <div class="text-body-1 font-weight-black">
                  {{
                    (overridePrice || selectedResource.dishResource.lowestUnitPrice) * activeUnitFactor
                        | priceDisplay
                  }}/{{ activeUnitName }}
                </div>
              </div>
              <div>
                <div class="text-caption">现在还有</div>
                <div class="text-body-1">{{ resourceDisplay(selectedResource) }}
                </div>
              </div>
            </div>
            <div v-if="inputMode===1" class="d-flex my-2 mt-6 px-1">
              <v-card :class="amount?' font-weight-black':''"
                      class="pa-4
            flex-grow-1
            flex-shrink-1
            mr-2
             text-h5
              text-no-wrap
               "
                      color="grey darken-3"
                      dark elevation="0" style="flex-basis: 0px">
                {{ amount ? amount : '请输入要操作的数量' }}
              </v-card>
              <div style="position: relative;height: 64px;width: 64px;overflow: visible;z-index: 2">
                <v-card v-for="unit in activeUnitList"
                        :key="unit.id"
                        :color="((activeUnitId===unit.id)?
                    'grey darken-2':'grey darken-4')"
                        class="text-h5 ml-1 d-flex align-center justify-center flex-shrink-0"
                        elevation="1"
                        height="64px"
                        width="64px"
                        @click="toggleUnit(unit.id)">
                  {{ unit.name }}
                </v-card>
              </div>


            </div>
            <template v-else>
              <div class="d-flex my-2 px-2 mt-6">
                <v-card :class="amount?' font-weight-black':''"
                        class="pa-4
            flex-grow-1
            flex-shrink-1
            mr-2
             text-h6
             d-flex
              text-no-wrap
               "
                        color="grey darken-3"
                        dark elevation="0" style="flex-basis: 0px">
                  {{ amount ? amount + ' €' : '请输入本次入库的价格' }}
                  <v-spacer></v-spacer>
                  <div class="text--hint"> 每</div>
                </v-card>
                <div style="position: relative;height: 64px;width: 64px;overflow: visible;z-index: 2">
                  <v-card v-for="unit in activeUnitList"
                          :key="unit.id"
                          :color="((activeUnitId===unit.id)?
                    'grey darken-2':'grey darken-4')"
                          class="text-h5 ml-1 d-flex align-center justify-center flex-shrink-0"
                          elevation="1"
                          height="64px"
                          width="64px"
                          @click="toggleUnit(unit.id)">
                    {{ unit.name }}
                  </v-card>
                </div>
              </div>
            </template>
          </v-card>
          <div class="pa-2">

            <keyboard
                :colors="currentColorList"
                :keys="currentKeyList"
                class="mt-8"
                @input="input"
            />
          </div>
        </v-card>
      </template>
      <template v-else>
        <v-card class="pa-4">
          <div class="text-h6">选择需要添加的Tag</div>
          <div class="mt-4" style="display: grid;grid-template-columns: repeat(4,minmax(0,1fr))">
            <v-card v-for="t in tags" :key="t.id" :color="selectedTagIdList.includes(t.id)?'success':''"
                    class="pa-1"
                    elevation="0" @click="toggleTag(t.id)">
              <v-responsive :aspect-ratio="1">
                <v-card :color="t.color" class=" pa-2 d-flex align-center justify-center" elevation="0" height="100%">
                  {{ t.name }}
                </v-card>
              </v-responsive>
            </v-card>
          </div>
          <div class="pa-1">
            <v-btn color="primary" elevation="0" large style="width: 100%" @click="submitTag">
              <v-icon left>mdi-check</v-icon>
              确定
            </v-btn>
          </div>

        </v-card>
      </template>
    </v-dialog>
    <v-dialog v-model="showPickReasonDialog" max-width="400px">
      <v-card class="pa-4">
        <div class="text-body-1">
          请输入一个备注
        </div>
        <v-text-field v-model="note" append-icon="mdi-check"
                      autofocus
                      class="mt-2" hide-details
                      outlined
                      placeholder="请输入备注"
                      @click:append="confirmNote()"
        >
        </v-text-field>
        <div class="mt-2"
             style="display: grid;grid-template-columns: repeat(4,minmax(0,1fr));grid-gap: 4px">
          <v-card v-for=" r in reasons" :key="r" class="d-flex align-center justify-center" color="#f6f6f6" elevation="0"
                  style="height: 48px"
                  @click="confirmNote(r)">
            {{ r }}
          </v-card>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Keyboard from "@/components/Keyboard"
import {
  amountUnitsDisplay,
  editTagForResource,
  getDetailList,
  getLowestUnitCount,
  getTimeSlotByTimeRange,
  storageChange
} from '@/js/api/api'
import {groupBy, mapValues} from 'lodash-es'
import ResourceNameDisplay from '@/components/ResourceNameDisplay.vue'
import IKUtils from 'innerken-js-utils'

const keyList = [
  "7", "8", "9", "C",
  "4", "5", "6", "出库",
  "1", "2", "3", "",
  "价格", "0", ".", "入库"
]

const priceList = [
  "7", "8", "9", "C",
  "4", "5", "6", "",
  "1", "2", "3", "",
  "返回", "0", ".", "确定"
]

const priceColor = [
  "", "", "", "",
  "", "", "", "",
  "", "", "", "",
  "error darken-2", "", "", "success darken-2 "
]

const colors = [
  "", "", "", "error darken-2 ",
  "", "", "", "warning darken-2 ",
  "", "", "", "",
  "primary darken-2", "", "", "success darken-2 "
]

const key = 'reason'

function saveReason (reason) {

  const currentReasonList = getReason()

  if (!currentReasonList.includes(reason)) {
    currentReasonList.unshift(reason)
  }

  localStorage.setItem(key, JSON.stringify(currentReasonList))
  return getReason()
}

function getReason () {
  const str = localStorage.getItem(key) ?? '[]'
  return JSON.parse(str) ?? []
}


const InputMode = {
  Price: 0,
  Amount: 1
}
export default {
  name: "NormalPage",
  components: {ResourceNameDisplay, Keyboard},
  props: {
    resource: {},
    currentTimeRange: {},
    editMode: {},
    tags: {}
  },
  data () {
    return {
      inputMode: InputMode.Amount,
      overridePrice: null,
      hideUnitPick: true,
      amount: '',
      showEditDialog: false,
      selectedResource: null,
      activeUnitId: null,
      showPickReasonDialog: false,
      outRecordDict: [],
      sign: 1,
      note: '',
      reasons: getReason(),


      selectedTagIdList: []
    }
  },
  computed: {
    timeRange () {
      return getTimeSlotByTimeRange(this.currentTimeRange)
    },
    currentKeyList () {
      return this.inputMode === InputMode.Amount ? keyList : priceList
    },
    currentColorList () {
      return this.inputMode === InputMode.Amount ? colors : priceColor
    },
    activeUnitName () {
      return this.selectedResource.unitSetWithUnit.resourceUnits.find(it => it.id === this.activeUnitId)?.name ?? ''
    },
    activeUnitFactor () {
      return getLowestUnitCount(1, this.activeUnitId, this.selectedResource.unitSetWithUnit.resourceUnits)
    },
    activeUnitList () {
      return this.selectedResource.unitSetWithUnit.resourceUnits.filter(it => !this.hideUnitPick || it.id === this.activeUnitId)
    }
  },
  watch: {
    timeRange: {
      immediate: true,
      async handler (val) {
        await this.refreshOutRecord(val)
      }
    }
  },
  methods: {
    toggleTag (tagId) {
      if (this.selectedTagIdList.includes(tagId)) {
        this.selectedTagIdList = this.selectedTagIdList.filter(it => it !== tagId)
      } else {
        this.selectedTagIdList.push(tagId)
      }
    },
    async refreshOutRecord (timeRange) {
      this.outRecordDict = mapValues(groupBy((await getDetailList(...timeRange)).filter(it => {
        return it.changeLog.operation === 'Out'
      }), (it) => it.dishResource.dishResource.id), (it) => {
        return Math.abs(it.reduce((sum, i) => sum + i.changeLog.amount, 0))
      })
    },
    async input (key) {
      switch (key) {
        case '出库':
        case '入库':
          if (!this.amount) {
            return
          }
          this.sign = key === '出库' ? -1 : 1
          this.showEditDialog = false
          this.showNote()
          break
        case '价格':
          this.inputMode = InputMode.Price
          this.amount = ''
          break
        case '返回':
          this.inputMode = InputMode.Amount
          this.amount = ''
          break
        case '确定':
          this.inputMode = InputMode.Amount
          this.overridePrice = this.amount / this.activeUnitFactor
          this.amount = ''
          break
        case 'C':
          this.amount = ''
          break
        default:
          this.amount += key
      }
    },
    showNote () {
      this.note = ''
      this.showPickReasonDialog = true
      this.reasons = getReason()
    },
    confirmNote (note = '') {
      if (!note) {
        note = this.note
      }
      this.storageChange(this.sign, note)
      this.showPickReasonDialog = false
    },
    async storageChange (sign = -1, note = '') {
      if (note) {
        saveReason(note)
      }
      IKUtils.showLoading()
      await storageChange(
          this.selectedResource.dishResource.id,
          this.activeUnitId,
          this.amount * sign,
          this.overridePrice || this.selectedResource.dishResource.lowestUnitPrice,
          note
      )
      await this.refreshOutRecord(this.timeRange)
      IKUtils.toast()
      this.$emit('ok')
    },
    resourceDisplay (r, overrideAmount = null) {
      return amountUnitsDisplay(overrideAmount ?? r.dishResource.currentCount, r.unitSetWithUnit.resourceUnits)
    },
    editResource (resource) {
      this.amount = ''
      this.inputMode = InputMode.Amount
      this.selectedResource = resource
      this.activeUnitId = this.selectedResource.unitSetWithUnit.resourceUnits[0].id
      this.selectedTagIdList = this.selectedResource.resourceTags.map(it => it.id)
      this.showEditDialog = true
    },
    toggleUnit (unitName) {
      if (this.hideUnitPick) {
        this.hideUnitPick = false
      } else {
        this.activeUnitId = unitName
        this.hideUnitPick = true
      }
    },
    async submitTag () {
      this.showEditDialog = false
      await editTagForResource(this.selectedResource.dishResource.id, this.selectedTagIdList)
      this.$emit('ok')
    }

  }

}
</script>

<style scoped>
.hideScroll::-webkit-scrollbar {
  display: none;
}
</style>
