import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import GlobalConfig, {loadConfig} from "@/js/LocalGlobalSettings"
import Print from 'vue-print-nb/print'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
Vue.config.productionTip = false
Vue.use(Print)

dayjs.locale('zh-cn')
dayjs.extend(relativeTime)
dayjs.extend(duration)
console.log(dayjs.duration(1, 'd').humanize())
export const host = 'http://resource9002.ngrok.aaden.io/'

async function initial() {
    await loadConfig()
    i18n.locale = GlobalConfig.lang.toLowerCase()
    new Vue({
        i18n, vuetify, render: h => h(App)
    }).$mount('#app')
}

Vue.filter('priceDisplay', function (price) {
    const res = parseFloat(price).toFixed(2) === '-0.00' ? '0.00' : parseFloat(price).toFixed(2)
    return res.replace('.', ',') + ' €'
})


Vue.filter('dayDisplay', function (n) {
    return dayjs.duration(n, 'd').humanize()
})

Vue.filter('beautifulTime', function (timeStamp) {
    return dayjs(timeStamp, 'YYYY-MM-DD HH:mm:ss').format('DD.MM/HH:mm')
})

initial()
